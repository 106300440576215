<template>
	<div class="select-container-section">
		<div class="select-container">
			<div class="select-holder report-type-select-holder" data-selectid="type">
				<span class="select-label">Report Type</span>
				<select id="type-select" v-model="selections.reporttype">
					<option v-for="rep in reportObjects" :key="rep.id" :value="rep.lookupval">{{rep.title}}</option>
				</select>
			</div>
			<div v-if="showSelections.market" class="select-holder hideselect" data-selectid="market">
				<span class="select-label">Market</span>
				<select id="market-select" v-model="selections.marketid">
					<option v-for="mkt in marketsForUser" :key="mkt.marketId" :value="mkt.marketId">{{mkt.marketName}}</option>
				</select>
			</div>
			<div v-if="showSelections.brand" class="select-holder hideselect" data-selectid="station">
				<span class="select-label">Brand</span>
				<select id="station-select" v-model="selections.stationid">
					<option v-for="stn in stationsAvail" :key="stn.stationId" :value="stn.stationId">{{stn.stationName}}</option>
				</select>
			</div>
			<div v-if="showSelections.period" class="select-holder hideselect" data-selectid="period">
				<span class="select-label">Period</span>
				<select id="period-select" v-model="selections.period">
					<option v-for="p in $store.state.periods" :key="p" :value="p">{{p}}</option>
				</select>
			</div>
			<div v-if="showSelections.property" class="select-holder hideselect" data-selectid="propertytype">
				<span class="select-label">Property</span>
				<select id="propertytype-select" v-model="selections.property">
					<option value="Era Breakdown">Era</option>
					<option value="Mood Breakdown">Mood</option>
					<option value="Rotation Breakdown">Rotation</option>
					<option value="Tempo Breakdown">Tempo</option>
					<option value="Type Breakdown">Type</option>
				</select>
			</div>
			<div v-if="showSelections.daypart" class="select-holder hideselect" data-selectid="daypart">
				<span class="select-label">Daypart</span>
				<select id="daypart-select" v-model="selections.daypart">
					<option v-for="p in $store.state.dayparts" :key="p.lookupval" :value="p.lookupval">{{p.title}}</option>
				</select>
			</div>
			<div v-if="showSelections.logdate"  class="select-holder hideselect" data-selectid="logdate">
				<span class="select-label" style="display: block; margin-bottom: 10px;">Date</span>
				<b-form-datepicker class="datechoose"
						v-model="selections.logdate"
						:date-format-options="dateFormatOptions"
						locale="en" placeholder="Choose"
				></b-form-datepicker>
			</div>
			<div v-if="showSelections.startdate" class="select-holder hideselect" data-selectid="logdateStart">
				<span class="select-label" style="display: block; margin-bottom: 10px;">Start Date</span>
				<b-form-datepicker class="datechoose"
						v-model="selections.logdateStart"
						:date-format-options="dateFormatOptions"
						locale="en" placeholder="Choose"
				></b-form-datepicker>
			</div>
			<div v-if="showSelections.enddate"  class="select-holder hideselect" data-selectid="logdateEnd">
				<span class="select-label" style="display: block; margin-bottom: 10px;">End Date</span>
				<b-form-datepicker class="datechoose"
						v-model="selections.logdateEnd"
						:date-format-options="dateFormatOptions"
						locale="en" placeholder="Choose"
				></b-form-datepicker>
			</div>

			<button v-if="selections.reporttype !== 'AudioDownload'" type="button" @click="$emit('getreport', selections)" id="processbtn">Process</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "MusicSelects",
	components: {

	},
	props: {
		incomingreporttype: String,
	},
	data: function () {
		return {
			selections: {
				reporttype: null,
				marketid: null,
				stationid: null,
				period: null,
				property: null,
				daypart: "BMAD-MF",
				logdate: null,
				logdateStart: null,
				logdateEnd: null,
			},
			dateFormatOptions: { year: 'numeric', month: 'short', day: '2-digit' },

			musicroutes: [
				{name: 'StationPlaylistLog', url: '/music-reports/brand-playlist-log'},
				{name: 'StationApraReport', url: '/music-reports/station-apra-report'},
				{name: 'StationPpcaReport', url: '/music-reports/station-ppca-report'},
				{name: 'StationPpcaReport18hr', url: '/music-reports/station-ppca-report-18hr'},
				{name: 'TopTracks', url: '/music-reports/top-tracks'},
				{name: 'HotTracks', url: '/music-reports/hot-tracks'},
				{name: 'NewTracks', url: '/music-reports/new-tracks'},
				{name: 'TopMarketTracks', url: '/music-reports/top-market-tracks'},
				{name: 'MarketSideBySideLog', url: '/music-reports/market-side-by-side-log'},
				{name: 'AudioDownload', url: '/music-reports/audio-download'},
				{name: 'MarketDNAComparison', url: '/music-reports/market-dna-comparison'},
				{name: 'StationFingerprint', url: '/music-reports/brand-dna'},
			],
		}
	},
	computed: {
		reportObjects() {
			if(this.$store.state.user.isAdmin) {
				return this.$store.state.user.allowedReportObjects;
			}
			return this.$store.state.user.allowedReportObjects.filter(item => item.devonly === false);
		},
		marketsForUser() {
			return this.$store.state.marketsAdminList.filter(item => this.$store.state.user.allMarketPermIds.includes(item.marketId));
		},
		stationsAvail() {
			if(this.selections.marketid != null && this.selections.marketid > 0) {
				var arr = this.$store.state.stationMarketMappings.filter(item => item.marketId === this.selections.marketid);
				return this.sortArrayByKey(arr, 'stationName');
			}
			return [];
		},
		showSelections() {
			return this.$store.state.musicdropdownshow;
		},
		chosenReport() {
			return this.selections.reporttype;
		}
	},
	methods: {

	},
	watch: {
		chosenReport() {
			var myitem = this.musicroutes.find(item => item.name === this.chosenReport);
			if(myitem) {
				if (this.$router.currentRoute.path !== myitem.url) {
					this.$router.push(myitem.url);
				}
			}
		},
		selections() {
			this.$emit('selectionchange', this.selections);
		},
	},
	mounted() {
		// let reportname = this.$route.params.reportname;
		// console.log(reportname);
		var inpath = this.$router.currentRoute.path;
		var myitem = this.musicroutes.find(item => item.url === inpath);
		if(myitem) {
			if (this.$router.currentRoute.path !== myitem.url) {
				this.$router.push(myitem.url);
			}
			this.selections.reporttype = myitem.name;
		}
		this.$emit('selectionchange', this.selections);
	}
}
</script>

<style scoped>
.select-container-section {
	background-color: #DCE0E5;
	border-bottom: 1px solid #CCC;
}
.select-container {
	margin: 70px auto 0;
	padding: 18px 60px 28px 60px;
	max-width: 1400px;
}
.select-holder {
	width: 15%;
	display: inline-block;
	padding: 0 5px;
}
.select-label {
	width: 100%;
	color: #36373a;
	font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	font-size: 15px;
}
.report-type-select-holder {
	width: 20%;
}
#type-select, #market-select, #period-select, #daypart-select,
#station-select, .datechoose, #logdateStart-select, #logdateEnd-select,
#propertytype-select, #end-time-audio, #start-time-audio {
	height: 40px;
	width: 100%;
	border: none;
	background: #fff;
	font-size: 13px;
	margin-top: 10px;
	font-family: 'Open Sans', sans-serif;;
}
.datechoose {

}
#processbtn {
	height: 40px;
	margin-left: 5px;
	background: #F38230;
	color: #fff;
	text-transform: uppercase;
	width: 140px;
	border: none;
	border-radius: 5px;
	font-family: 'Quicksand', sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 10px;
	letter-spacing: 0.03em;
}
#processbtn:hover {
	background: #e96c00;
}
</style>