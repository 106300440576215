<template>
	<div class="" :class="{bgpage : $store.state.user.id === 0}" style="padding-top: 50px; min-height: 100vh;">
		<div id="app-container">
			<div id="logo-holder">
				<a href="https://arn.com.au">
					<img src="@/assets/logo.png" alt="Logo" />
				</a>
			</div>

			<div v-cloak>

				<div id="form-wrapper">
					<div>
						<h1>{{heading}}</h1>

						<div class="header-notes-login">
							<div class="header-notes-login">
								<p>
									<component :is="transformed"></component>
								</p>
							</div>

						</div>

					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>

export default {
	name: 'Error',
	components: {},
	data() {
		return {
			errorId: 0,
			heading: '',
			content: '',
		}
	},
	computed: {
		transformed() {
			return {
				template: '<div>'+this.content+'</div>',
				props: this.$options.props
			}
		}
	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		let urlparams = this.$route.query;
		this.heading = "Error - Tracka";
		this.content = "Ooops - there seemed to be a problem.  Please try what you were doing again.";
		if(urlparams.errorId !== undefined) {
			this.errorId = parseInt(urlparams.errorId);
			if(this.errorId === 404) {
				this.heading = "404 Not Found";
				this.content = "Sorry, but we couldn't find the page you're looking for.";
			}
		}
		else {
			this.heading = "404 Not Found";
			this.content = "Sorry, but we couldn't find the page you're looking for.";
		}
		document.title = this.heading;
	},
	created() {

	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
#app-container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
.bgpage {
	/*background-image: url('~@/assets/form-bg.jpg');*/
}
#logo-holder img {
	height: 52px;
}
#form-wrapper {
	background: #FFF;
	border-radius: 30px;
	padding: 50px;
	margin-top: 40px;
	margin-bottom: 80px;
	box-shadow: 10px 0px 40px 6px rgba(0,0,0,.08);
}
.header-notes-login {
	padding-bottom: 10px;
}
</style>
