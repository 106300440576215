<template>
	<div>
		<div class="report-top">
			<div class="headingHold">
				<h2 id="resultsHeading">
					{{heading}}
				</h2>
			</div>

			<div style="width: 300px; display: flex; align-items: center">
				<b-form-input
						id="filter-input"
						v-model="filter"
						type="search"
						placeholder="Type to Search"
						style="font-size: 13px;"
				></b-form-input>
				<div v-if="returndata.length > 0" class="icon-excel-hold" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
			</div>
		</div>

		<div class="content-container report-section">
			<b-table :fields="fields" :items="returndata" :tbody-tr-class="'trow'" :filter="filter">
				<template #cell(songItem.title)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder">
						<i class="fa fa-building cursorPointer rowIcon" @click="drillDown('SongSpinsMarketSummary', data.item);" title="Click to view market plays"></i>
						<i v-if="$store.state.user.isAdmin !== 1" class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(data.item);" title="Report an error with this item"></i>
						<i v-if="$store.state.user.isAdmin === 1" class="fa fa-angry cursorPointer rowIcon" @click="showSongEditCard(data.item.songItem.trackaSongId);" title="Edit song details"></i>
					</div>
				</template>

				<template #cell(stationPlays)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder">
						<i class="fa fa-clock cursorPointer rowIcon" @click="drillDown('SongSpins', data.item, data.item.stationId);" :title="'Click to view other '+getStationNameFromId(data.item.stationId)+' play times'"></i>
					</div>
				</template>

				<template #cell(compStationPlays)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder">
						<i class="fa fa-clock cursorPointer rowIcon" @click="drillDown('SongSpins', data.item, data.item.compStationId);" :title="'Click to view other '+getStationNameFromId(data.item.compStationId)+' play times'"></i>
					</div>
				</template>

			</b-table>
		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
		<SongEditOverlay v-if="$store.state.songEditCardShown"></SongEditOverlay>
	</div>
</template>

<script>
import axios from "axios";
import BugReporter from "@/components/BugReporter";
import SongEditOverlay from "@/components/SongEditOverlay.vue";

export default {
	name: "StationFingerprintSongList",
	components: {SongEditOverlay, BugReporter},
	props: {
		item: Object,
		selections: Object,
		fingerprintreturndata: Object,
	},
	data: function () {
		return {
			reportType: 'StationFingerprintSongList', //for API URL
			fetching: false,
			reportTitle: 'Shared Tracks',
			heading: 'Shared Tracks',
			filter: '',
			defaultFields: [
				{key: 'songItem.artist', label: 'Artist', sortable: true},
				{key: 'songItem.title', label: 'Title', sortable: true},
				// {key: 'label', label: 'Label', sortable: true},
				{key: 'songItem.year', label: 'Release Year', sortable: true},
				{key: 'stationPlays', label: 'First Station Plays', sortable: true},
				{key: 'stationSongRotation', label: 'First Station Rot', sortable: true},
			],
			fields: [],
			returndata: [],
		}
	},
	computed: {

	},
	methods: {
		drillDown(newreporttype, item, usecompstation) {
			if(usecompstation && usecompstation > 0) {
				item.stationId = usecompstation
			}
			this.$emit('switchdrilldown', {
				newreporttype: newreporttype,
				item: item
			});
		},
		getReport() {
			let mainStationId = this.item.stationId
			let mainStationName = this.getStationNameFromId(mainStationId)
			let compStationId = this.item.compStationId
			let compStationName = this.getStationNameFromId(compStationId)
			this.fields = this.defaultFields
			if(mainStationId === compStationId) {
				this.heading = mainStationName + " exclusive tracks"
			}
			else { //if this is a true comp stations add the comp fields
				this.heading = mainStationName + " tracks shared with " + compStationName
				this.fields.push({key: 'compStationPlays', label: compStationName+" Plays"})
				this.fields.push({key: 'compSongRotation', label: compStationName+" Rot"})
			}
			this.fields[3].label = mainStationName+" Plays"
			this.fields[4].label = mainStationName+" Rot"
			let d = []
			let tracks = this.fingerprintreturndata.tracks
			for (let item of this.fingerprintreturndata.sharedSongIds[compStationId]) {
				let songOb = tracks[item.trackId]
				if(songOb) {
					let row = {
						songItem: songOb,
						stationPlays: item.plays,
						stationSongRotation: item.rotation,
						stationId: mainStationId,
						compStationId: compStationId,
					}
					if (mainStationId !== compStationId) {
						row.compStationPlays = item.playsOtherStation
						row.compSongRotation = item.rotationOtherStation
					}
					d.push(row)
				}
			}
			d.sort((a,b) => b.stationPlays - a.stationPlays);
			this.returndata = d
		},
		toExcel() {
			let form = {}
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata)
			form.layout = this.reportType
			form.filename = this.getStationNameFromId(this.selections.stationid)
			let targ = '/post/get-excel.php'
			axios.post(targ, form).then(function (response) {
				window.location = response.data
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {
		item() {
			this.getReport()
		},
		fetching() {
			this.$store.commit('showLoader', this.fetching)
		}
	},
	mounted() {
		this.getReport()
	}
}
</script>

<style scoped>

</style>