<template>
	<div>
		<div class="report-top">
			<div class="headingHold">
				<h2 id="resultsHeading">
					{{heading}}
				</h2>
			</div>

			<div style="width: 300px; display: flex; align-items: center">
				<b-form-input
						id="filter-input"
						v-model="filter"
						type="search"
						placeholder="Type to Search"
						style="font-size: 13px;"
				></b-form-input>
				<div v-if="returndata.length > 0" class="icon-excel-hold" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
			</div>
		</div>

		<div class="content-container report-section">
			<b-table :fields="fields" :items="returndata" :tbody-tr-class="'trow'" :filter="filter">
				<template #cell(songItem.title)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder">
						<i class="fa fa-clock cursorPointer rowIcon" @click="drillDown('SongSpins', data.item);" :title="'Click to view other '+getStationNameFromId(data.item.stationId)+' play times'"></i>
						<i class="fa fa-building cursorPointer rowIcon" @click="drillDown('SongSpinsMarketSummary', data.item);" title="Click to view market plays"></i>
						<i v-if="$store.state.user.isAdmin !== 1" class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(data.item);" title="Report an error with this item"></i>
						<i v-if="$store.state.user.isAdmin === 1" class="fa fa-angry cursorPointer rowIcon" @click="showSongEditCard(data.item.songItem.trackaSongId);" title="Edit song details"></i>
					</div>
				</template>
			</b-table>
		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
		<SongEditOverlay v-if="$store.state.songEditCardShown"></SongEditOverlay>
	</div>
</template>

<script>
import axios from "axios";
import BugReporter from "@/components/BugReporter";
import SongEditOverlay from "@/components/SongEditOverlay.vue";

export default {
	name: "StationFingerprintDrilldown",
	components: {
		SongEditOverlay,
		BugReporter
	},
	props: {
		item: Object,
		selections: Object,
		drilldownpropname: String,
		fingerprintreturndata: Object,
	},
	data: function () {
		return {
			reportType: 'StationFingerprintDrilldown', //for API URL
			fetching: false,
			reportTitle: 'Song List',
			heading: 'Song List',
			filter: '',
			fields: [
				{key: 'songItem.artist', label: 'Artist', sortable: true},
				{key: 'songItem.title', label: 'Title', sortable: true},
				{key: 'songItem.year', label: 'Year', sortable: true},
				{key: 'songRotationCategory', label: 'Rotation Category', sortable: true},
				{key: 'stationPlays', label: 'Station Plays', sortable: true},
			],
			returndata: [],
		}
	},
	computed: {

	},
	methods: {
		drillDown(newreporttype, item) {
			this.$emit('switchdrilldown', {
				newreporttype: newreporttype,
				item: item
			});
		},
		getReport() {
			let mainStationId = this.item.stationId
			let mainStationName = this.getStationNameFromId(mainStationId)
			let d = []
			let tracks = this.fingerprintreturndata.tracks
			for (let it of this.item.trackIds[this.drilldownpropname]) {
				let songOb = tracks[it]
				let row = {
					songItem: songOb,
					stationPlays: this.fingerprintreturndata.songItemsByStationId[mainStationId][it].plays,
					songRotationCategory: songOb.rotationName,
					stationId: mainStationId,
				}
				d.push(row)
			}
			d.sort((a,b) => b.stationPlays - a.stationPlays);
			this.returndata = d
			this.heading = mainStationName+ " "+this.drilldownpropname+" song list";
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata);
			form.layout = this.reportType;
			form.filename = this.getMarketFromId(this.selections.marketid);
			var targ = '/post/get-excel.php';
			axios.post(targ, form).then(function (response) {
				window.location = response.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		item() {
			this.getReport();
		},
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.getReport();
	}
}
</script>

<style scoped>

</style>