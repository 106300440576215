<template>
	<div class="">
		<div class="content-container report-section">
			<AudioPlayer :audioitem="playerAudioItem" :selections="selections"></AudioPlayer>
		</div>
		<DrilldownHolder v-if="drilldownOverlayShow" :drilldownreporttype="drilldownReportType"
			:drilldownitem="drilldownitem" v-on:closedrilldown="drilldownOverlayShow = false"
			:selections="selections"
		></DrilldownHolder>
		<div v-if="started" class="content-container report-section" :class="{sectionhide : drilldownOverlayShow === true}">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
						<span class='headsub' title='Ticking the box will also show items less than 90sec duration.  This is useful for mashups but can also show false positives eg song grabs used in music demonstrators or forward teases.'>
							Show short plays
							<input id='shortplaycheckbox' type='checkbox' v-model="showShortPlays" />
						</span>
						<span class='headsub' title='Ticking the box will show the estimaed record label column.'>
							Show record label
							<input id='reclabelcheckbox' type='checkbox' v-model="showLabel" />
						</span>
					</h2>
				</div>

				<div style="width: 300px; display: flex; align-items: center">
					<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Type to Search"
							style="font-size: 13px;"
					></b-form-input>
					<div class="icon-excel-hold" v-if="tableData.length > 0" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
				</div>
			</div>

			<b-table :fields="fields" :items="tableData" :tbody-tr-class="rowClass" :filter="filter">
				<template #cell(songPlayedTimeLocal)="data">
					<span>{{ data.value }}</span>
					<i class="fa fa-volume-up cursorPointer rowIcon" title="Listen to audio" v-if="data.item.isTOH !== true"
						@click="getAndPlayAudio(data.item)"
					></i>
					<AudioDownloadFromStationLog :selections="selections" :item="data.item"></AudioDownloadFromStationLog>
				</template>

				<template #cell(songItem.title)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder" v-if="data.item.isTOH !== true">
						<i class="fa fa-clock cursorPointer rowIcon" @click="drillDown('SongSpins', data.item);" :title="'Click to view other '+getStationNameFromId(data.item.stationId)+' play times'"></i>
						<i class="fa fa-building cursorPointer rowIcon" @click="drillDown('SongSpinsMarketSummary', data.item);" title="Click to view market plays"></i>
						<i v-if="$store.state.user.isAdmin !== 1" class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(data.item);" title="Report an error with this item"></i>
						<i v-if="$store.state.user.isAdmin === 1" class="fa fa-angry cursorPointer rowIcon" @click="showSongEditCard(data.item.songItem.trackaSongId);" title="Edit song details"></i>
					</div>
				</template>
			</b-table>

		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
		<SongEditOverlay v-if="$store.state.songEditCardShown"></SongEditOverlay>
	</div>
</template>

<script>
import axios from "axios";
import AudioPlayer from "@/components/AudioPlayer";
import AudioDownloadFromStationLog from "@/components/AudioDownloadFromStationLog";
import BugReporter from "@/components/BugReporter";
import DrilldownHolder from "@/components/DrilldownHolder";
import SongEditOverlay from "@/components/SongEditOverlay.vue";
export default {
	name: "BrandPlaylistLog",
	components: {
		SongEditOverlay,
		DrilldownHolder,
		BugReporter,
		AudioDownloadFromStationLog,
		AudioPlayer,
	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: true,
				period: false,
				property: false,
				daypart: false,
				logdate: true,
				startdate: false,
				enddate: false,
			},
			reportType: 'StationPlaylistLog', //for API URL
			fetching: false,
			started: false,
			reportTitle: 'Brand Playlist Log',

			showShortPlays: false,
			showLabel: false,
			heading: '',
			drilldownOverlayShow: false,
			drilldownReportType: '',
			drilldownitem: null,

			returndata: [],
			filter: '',
			playerAudioItem: null,
			yearUpdaterShown: false,
			yearUpdaterSongId: null,
		}
	},
	computed: {
		fields() {
			let fields = [
				{key: 'songPlayedTimeLocal', label: 'Start Time'},
				{key: 'songItem.title', label: 'Title'},
				{key: 'songItem.artist', label: 'Artist'},
			]
			if(this.showLabel) fields.push({key: 'songItem.label', label: 'Label'})
			fields.push(
				{key: 'songItem.year', label: 'Release Year'},
				{key: 'songRotationHrsForStation28d', label: 'Avg Rotation (Hrs)'},
				{key: 'songPlayedDurationMinSec', label: 'Duration'},
			)
			return fields
		},
		tableData() {
			if(this.showShortPlays) return this.returndata
			else return this.returndata.filter(item => item.validPlay === "Y")
		}
	},
	methods: {
		showYearUpdater(songid) {
			this.yearUpdaterShown = true
			this.yearUpdaterSongId = songid
		},
		closeYearUpdater(arr) {
			if(arr) {
				if(arr.newYear && arr.newYear > 0 && arr.trackaSongId && arr.trackaSongId.length > 0) {
					this.returndata.forEach(item => {
						if(item.songItem && item.songItem.trackaSongId && item.songItem.trackaSongId === arr.trackaSongId) {
							item.songItem.year = arr.newYear
						}
					})
				}
			}
			this.yearUpdaterShown = false
			this.yearUpdaterSongId = null
		},
		drillDown(reporttype, item) {
			this.drilldownReportType = reporttype;
			this.drilldownitem = item;
			this.drilldownOverlayShow = true;
		},
		getAndPlayAudio(item) {
			this.playerAudioItem = item;
		},
		rowClass(item, type) {
			var str = 'trow';
			if (!item || type !== 'row') return
			if (item.isTOH === true) str += ' tohrow';
			return str;
		},
		getReport() {
			if(this.fetching === false) {
				this.drilldownOverlayShow = false; //force hide the drilldown reports
				if(this.selections.stationid === null || this.selections.marketid === null || this.selections.logdate === null) {
					return false;
				}
				let self = this
				let parstr = "marketId="+ this.selections.marketid + "&stationId=" + this.selections.stationid + "&logDate=" + this.selections.logdate;
				let targ = '/api-web/get-station-log.php?'+parstr;

				this.$store.commit('setLastQueryUrl', targ);
				this.fetching = true;
				document.title = "Fetching data...";
				axios.get(targ).then(function(response) {
					let ret = response.data; //response.data is returned info
					document.title = 'Tracka - '+self.reportTitle;
					//for brand log we need to add TOH markers
					let resarr = [];
					let lasthour = 0;
					//add a TOH marker
					resarr.push(self.newTOH(0));
					for(let i=0; i<ret.length; i++) {
						let myitem = ret[i];
						let myTOH = parseInt(myitem.songPlayedTimeLocal.split(":")[0]);
						if(myTOH > lasthour) {
							resarr.push(self.newTOH(myTOH));
							lasthour = myTOH;
						}
						resarr.push(myitem);
					}
					self.returndata = resarr;
					self.fetching = false;
					self.started = true; //first one has come back
					self.heading = self.getStationNameFromId(self.selections.stationid) + " Log "
					self.sendLoggingInfo(self.reportType, self.selections)
				}).catch(function(error) {
					console.log(error)
					self.fetching = false
				});
			}
		},
		newTOH(hr) {
			return {
				songPlayedTimeLocal: hr + ':00:00 Hr',
				validPlay: "Y",
				isTOH: true,
			};
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.tableData);
			form.layout = this.reportType;
			form.filename = this.getStationNameFromId(this.selections.stationid);
			var targ = '/post/get-excel.php';
			axios.post(targ, form).then(function (response) {
				window.location = response.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects);
		document.title = 'Tracka - '+this.reportTitle;
	}
}
</script>

<style scoped>


</style>