<template>
	<div class="">
		<DrilldownHolder v-if="drilldownOverlayShow" :drilldownreporttype="drilldownReportType"
			:drilldownitem="drilldownitem" v-on:closedrilldown="drilldownOverlayShow = false"
			:selections="selections" :drilldownpropname="drilldownpropname" :fingerprintreturndata="returndata"
		></DrilldownHolder>
		<div v-if="started" class="content-container report-section" :class="{sectionhide : drilldownOverlayShow === true}">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
					</h2>
				</div>

			</div>

			<div id='stacked-bar-holder' style='max-width: 1400px;'>
				<div class='legend-holder'>
					<span class='legend-item' v-for="(prop, index) in stationResults[0].results" :key="index" :style="'background-color: '+$store.state.chartColours[index]">{{prop.name}}</span>
				</div>

				<div v-for="(myitem, index) in stationResults" :key="index">
					<p style="margin: 20px 0 5px; font-size: 16px;">{{getStationNameFromId(myitem.stationId)}}</p>
					<div class='stackedbar'>
						<div v-for="(prop, index) in myitem.results" class='stack-section' :key="index"
							:title="prop.name+' - '+prop.pctrnd+'%. Click to view song list.'"
							:style="'background-color: '+$store.state.chartColours[index]+'; width:'+prop.pct+'%;'"
							data-drilldownvalue="drilldownval" data-drilldownstationname="myitem[title]"
							@click="drillDown(prop.name, myitem)"
							>
							<span>{{prop.pctrnd}}%</span>
						</div>
					</div>
				</div>

				<div style="margin-top: 20px;" v-if="reportPropertyType === 'Rotation Breakdown'" class="">
					<p class="" style="font-size: 12px;">
						CURRENT: Song played on rotation less than 12hr
						<br>
						RECURRENT: Song played on rotation 12hr to 36hr
						<br>
						GOLD: Song played on rotation greater than 36hr
					</p>
					<br>
				</div>

				<div class='legend-holder' style="margin: 50px 0 100px;">
					<span class='legend-item' v-for="(prop, index) in stationResults[0].results" :key="index" :style="'background-color: '+$store.state.chartColours[index]">{{prop.name}}</span>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import DrilldownHolder from "@/components/DrilldownHolder";
export default {
	name: "MarketDNAComparison",
	components: {
		DrilldownHolder,
	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: false,
				period: false,
				property: true,
				daypart: true,
				logdate: false,
				startdate: false,
				enddate: false,
			},
			reportType: 'MarketDNAComparison', //for API URL
			fetching: false,
			started: false,
			reportTitle: "Market Comparison",

			heading: 'Market Comparison',
			reportPropertyType: '',

			returndata: [],

			drilldownOverlayShow: false,
			drilldownReportType: '',
			drilldownitem: null,
			drilldownpropname: '',

			stationResults: [],
		}
	},
	computed: {

	},
	methods: {
		drillDown(propname, item) {
			this.drilldownReportType = 'StationFingerprintDrilldown';
			this.drilldownitem = item;
			this.drilldownpropname = propname;
			this.drilldownOverlayShow = true;
		},
		getReport() {
			if(this.fetching === false) {
				this.drilldownOverlayShow = false; //force hide the drilldown reports
				if(this.selections.marketid === null || this.selections.property === null || this.selections.daypart === null) {
					return false
				}
				let self = this
				let parstr = "marketId="+this.selections.marketid+"&stationId="+this.selections.stationid + "&daypartName=" + this.selections.daypart
				let targ = '/api-web/station-fingerprint-dna.php?'+parstr
				this.$store.commit('setLastQueryUrl', targ)
				this.fetching = true
				document.title = "Fetching data..."
				axios.get(targ).then(function(response) {
					let ret = response.data //response.data is returned info
					document.title = 'Tracka - ' + self.reportTitle
					self.returndata = ret
					let propname = '';
					if(self.selections.property === 'Era Breakdown') propname = 'decadesByStation';
					else if(self.selections.property === 'Mood Breakdown') propname = 'moodByStation';
					else if(self.selections.property === 'Rotation Breakdown') propname = 'rotationByStation';
					else if(self.selections.property === 'Tempo Breakdown') propname = 'temposByStation';
					else if(self.selections.property === 'Type Breakdown') propname = 'genreByStation';
					if(propname !== '') {
						let d = []
						let tracksarrname = propname.replace('sByStation', '')
						tracksarrname = tracksarrname.replace('ByStation', '')
						tracksarrname = tracksarrname + 'SongIds'
						for(let sind=0; sind<ret.stationIds.length; sind++) {
							let stnid = ret.stationIds[sind]
							let item = {
								stationId: stnid,
								results: [],
								trackIds: ret[tracksarrname][stnid]
							}
							let stntotal = ret[propname][stnid]['total']
							for(let propitem in ret[propname][stnid]) {
								if(propitem === 'total') continue;
								let num = ret[propname][stnid][propitem]
								let pct = (num / stntotal) * 100
								item.results.push({
									name: propitem,
									pct: pct,
									pctrnd: Math.round(pct),
								})
							}
							d.push(item)
						}
						self.stationResults = d
					}
					self.fetching = false;
					self.started = true;
					self.heading = self.getMarketFromId(self.selections.marketid)+" "+self.selections.property+" comparison report (past 28 days)";
				}).catch(function(error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects);
		document.title = 'Tracka - '+this.reportTitle;
	}
}
</script>

<style scoped>
#stacked-bar-holder p {
	margin-bottom: 5px;
}
.stackedbar {
	width: 100%;
	height: 60px;
}
.stack-section {
	display: inline-flex;
	height: 60px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: opacity 0.3s ease-in-out;
}
.stack-section:hover {
	opacity: 0.7;
}
.stack-section span {
	display: flex;
	position: absolute;
	font-size: 10px;
	color: #FFF;
}
.legend-item {
	display: inline-block;
	padding: 2px 10px;
	border: 1px solid #BBB;
	margin-right: 8px;
	font-size: 12px;
	color: #FFF;
}
</style>