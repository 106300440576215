<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">
		<div id="app-container">


			<div v-cloak>

				<div id="form-wrapper">
					<div>
						<div id="logo-holder">
							<a href="https://app.tracka.app">
								<img src="@/assets/login-logo.png" alt="Logo" />
							</a>
						</div>
						<b-form>

							<b-form-group id="input-group-3" :state="emailState" :invalid-feedback="emailInvalidFeedback">
								<b-form-input id="input-3" v-model="email" type="email" placeholder="Email Address" :state="emailState"></b-form-input>
							</b-form-group>

							<b-form-group v-if="showingForgetPwd === false" id="input-group-4" :state="passState" :invalid-feedback="passInvalidFeedback">
								<b-form-input id="input-4" v-model="pass" type="password" placeholder="Password" :state="passState"></b-form-input>
							</b-form-group>

							<b-button v-if="showingForgetPwd === false" block class="login-button" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
								<span>Submit</span>
								<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
							</b-button>

							<b-button v-if="showingForgetPwd === true" block class="login-button" v-on:click="forgetPwd" v-bind:class="{'button-is-working':(form1working === true)}">
								<span>Resend Password</span>
								<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
							</b-button>

							<p v-if="showingForgetPwd === false" @click="showingForgetPwd = true" class="text-link">I've forgotten my password</p>
							<p v-if="showingForgetPwd" @click="showingForgetPwd = false" class="text-link">Cancel</p>

						</b-form>

					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";

export default {
	name: 'Login',
	components: {
		WorkingButtonInsert
	},
	data() {
		return {
			headerNotes: "",
			requestedLink: "", //for use after successful login

			showingForgetPwd: false,

			email: '',
			pass: '',

			checksTriggered: false,
			form1working: false,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			return 'Please enter a valid email address.'
		},
		passState() {
			if(this.checksTriggered === false && this.pass.length === 0) {
				return null;
			}
			else {
				if(this.pass.length < 6) return false;
				if(/[a-zA-Z]/g.test(this.pass) === false) return false;
				if(/\d/.test(this.pass) === false) return false;
				// if(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(this.pass) === false) return false;
			}
			return true;
		},
		passInvalidFeedback() {
			return 'Please enter your password.'
		},

	},
	methods: {
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.emailState, this.passState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				var form = {};
				form.email = this.email;
				form.pass = this.pass;
				var self = this;
				axios.post(
					"post/do-login.php",
					JSON.stringify({
						data: form,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					if(ret.error === 1) {
						self.form1working = false;
						self.$store.commit("showKalert", ret);
					}
					else if(ret.success === 1) {
						if(ret.objects.user) {
							self.$store.commit("setUser", ret.objects.user);
						}
						self.$router.push("/music");
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		forgetPwd() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.emailState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				var form = {};
				form.email = this.email;
				var self = this;
				axios.post(
					"post/resend-password.php",
					JSON.stringify({
						data: form,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					self.form1working = false;
					if(ret.error === 1) {
						self.$store.commit("showKalert", ret);
					}
					else if(ret.success === 1) {
						self.$store.commit("showKalert", ret);
						self.showingForgetPwd = false;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Tracka - Login";
		if(this.$store.state.sesscheckcomplete && this.$store.state.user.id > 0) {
			this.$router.push("/music");
		}
	},
	created() {
		//handle incoming GET parameters
		let urlparams = this.$route.query;
		if(urlparams.activated !== undefined && parseInt(urlparams.activated) === 1) {
			this.headerNotes = "Thanks for activating your account - you can now log in!";
		}
		if(urlparams.passreset !== undefined && parseInt(urlparams.passreset) === 1) {
			this.headerNotes = "Your password has been changed - you can now log in!";
		}
	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
.bgpage {
	background-image: url('~@/assets/login_bg-img.jpg');
	background-size: cover;
}
#app-container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	margin-bottom: 30px;
}
#logo-holder img {
	height: 52px;
}
#form-wrapper {
	padding: 60px;
	margin-top: 40px;
	margin-bottom: 80px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #dce0e5;
	border-radius: 10px;
}
#form-wrapper input {
	margin-bottom: 20px;
	border-radius: 6px;
	height: 60px;
	font-size: 18px;
}
.login-button {
	margin-bottom: 20px;
	border-radius: 6px;
	height: 60px;
	background: #f5821f;
	color: #fff;
	font-size: 22px;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	transition: ease-in-out 0.5s;
}
.login-button:hover {
	background: #e96c00;
}
.text-link {
	cursor: pointer;
	width: fit-content;
	padding-bottom: 1px;
	margin: 0 auto;
	border-bottom: 1px dotted transparent;
	transition: ease-in-out 0.5s;
}
.text-link:hover {
	border-bottom: 1px dotted #666;
}
</style>
