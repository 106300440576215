import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Login from "@/components/pageviews/Login";
import Logout from "@/components/pageviews/Logout";
import ErrorPage from "@/components/pageviews/ErrorPage";
import Admin from "@/components/pageviews/Admin";
import MusicHome from "@/components/pageviews/MusicHome";
import MusicReport from "@/components/pageviews/MusicReport";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './includes/style.css'
import axios from "axios";

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

//routing
const routes = [
	{path: '/login', component: Login},
	{path: '/logout', component: Logout},
	{path: '/admin', component: Admin},
	{path: '/error/:errorid', component: ErrorPage},
	{path: '/music', component: MusicHome},
	{path: '/music-reports', component: MusicReport},
	{path: '/music-reports/:reportname', component: MusicReport},
	{path: '/*', component: ErrorPage},
]

const router = new VueRouter({
	history: true,
	hashbang: false,
	routes: routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const store = new Vuex.Store({
	state: {
		sesscheckcomplete: false,
		kalert: {
			shown: false,
			text: "",
			type: 'danger',
		},
		activeSection: '',
		activeSectionAdmin: 'stations',
		getparams: {},
		user: {
			id: 0,
		},
		basicAuthAdmin: {
			username: 'tracka',
			password: 'pass'
		},
		stationsAdminList: [],
		timezonelist: [],
		stationMarketMappings: [],
		marketsAdminList: [],
		reportsAdminList: [],
		companiesAdminList: [],
		usersAdminList: [],
		songbugsAdminList: [],
		dayparts: [],
		periods: [],

		musicdropdownshow: {
			market: true,
			brand: true,
			period: false,
			property: false,
			daypart: false,
			logdate: true,
			startdate: false,
			enddate: false,
		},

		apiKevinUrl: "https://trackamedia.appspot.com/api/reports/v1/",
		apiMichaelUrl: "https://tracka-api.azurewebsites.net/",
		loaderShown: false,
		bugreportshown: false,
		songEditCardShown: false,
		songEditTrackaId: '',
		bugreportitem: {},
		lastQueryUrl: '',

		chartColours:  [
			'rgba(26, 188, 156, 0.6)',
			'rgba(46, 204, 113, 0.6)',
			'rgba(52, 152, 219, 0.6)',
			'rgba(155, 89, 182, 0.6)',
			'rgba(241, 196, 15, 0.6)',
			'rgba(230, 126, 34, 0.6)',
			'rgba(231, 76, 60, 0.6)',
			'rgba(149, 165, 166, 0.6)',
			'rgba(243, 166, 131, 0.6)',
			'rgba(247, 215, 148, 0.6)',
			'rgba(119, 139, 235, 0.6)',
			'rgba(231, 127, 103, 0.6)',
			'rgba(207, 106, 135, 0.6)',
			'rgba(120, 111, 166, 0.6)',
			'rgba(248, 165, 194, 0.6)',
			'rgba(99, 205, 218, 0.6)',
			'rgba(26, 188, 156, 0.6)',
			'rgba(46, 204, 113, 0.6)',
			'rgba(52, 152, 219, 0.6)',
			'rgba(155, 89, 182, 0.6)',
			'rgba(241, 196, 15, 0.6)',
			'rgba(230, 126, 34, 0.6)',
			'rgba(231, 76, 60, 0.6)'
		],
	},
	getters: {

	},
	mutations: {
		showLoader(state, bool) {
			state.loaderShown = bool;
		},
		showBugReporter(state, bool) {
			state.bugreportshown = bool;
		},
		setBugReportItem(state, obj) {
			state.bugreportitem = obj;
		},
		setLastQueryUrl(state, str) {
			state.lastQueryUrl = str;
		},
		setMusicDropdownItems(state, obj) {
			state.musicdropdownshow = obj;
		},
		setGetParams(state, obj) {
			state.getparams = obj;
		},
		setUser(state, obj) {
			state.user = obj;
		},
		showKalert(state, responseObject) {
			state.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) state.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			state.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) state.kalert.type = responseObject.type;
			state.kalert.shown = true;
			setTimeout(function() {
				state.kalert.shown = false;
			}, 7000);
		},
		closeKalert(state) {
			state.kalert.shown = false;
		},
		setSesscheckComplete(state, sesscheckbool) {
			state.sesscheckcomplete = sesscheckbool;
		},
		setActiveSection(state, sectionname) {
			state.activeSection = sectionname;
		},
		setActiveSectionAdmin(state, sectionname) {
			state.activeSectionAdmin = sectionname;
		},
		setAdminStationList(state, arr) {
			state.stationsAdminList = arr;
		},
		setAdminMarketList(state, arr) {
			state.marketsAdminList = arr;
		},
		setTimezoneList(state, arr) {
			state.timezonelist = arr;
		},
		setStationMarketMappingList(state, arr) {
			state.stationMarketMappings = arr;
		},
		setFromAdminReturns(state, ret) {
			if(ret.reports) {
				state.reportsAdminList = ret.reports;
			}
			if(ret.companies) {
				state.companiesAdminList = ret.companies;
			}
			if(ret.users) {
				state.usersAdminList = ret.users;
			}
			if(ret.songbugs) {
				state.songbugsAdminList = ret.songbugs;
			}
		},
		setFromPublicSelectReturns(state, ret) {
			if(ret.dayparts) {
				state.dayparts = ret.dayparts;
			}
			if(ret.periods) {
				state.periods = ret.periods;
			}
		},
	},
	actions: {},
});

Vue.mixin({
	methods: {
		sortArrayByKey: function(arr, keyname) {
			arr.sort((a, b) => {
				let compare = 0;
				if (a[keyname] > b[keyname]) {
					compare = 1;
				} else if (b[keyname] > a[keyname]) {
					compare = -1;
				}
				return compare;
			});
			return arr;
		},
		getStationNameFromId(stnid) {
			if(stnid) {
				var stnob = this.$store.state.stationsAdminList.find(item => item.stationId === stnid);
				return stnob.stationName;
			}
			return "";
		},
		// getStationIdFromName(stnname) {
		// 	if(stnname) {
		// 		let stnob = this.$store.state.stationsAdminList.find(item => item.stationName === stnname)
		// 		return stnob.stationId;
		// 	}
		// 	return "";
		// },
		getMarketFromId(mktid) {
			if(mktid) {
				var mymkt = this.$store.state.marketsAdminList.find(item => item.marketId === mktid);
				return mymkt.marketName;
			}
			return "";
		},
		getUserNameFromId(uid) {
			if(uid) {
				var myu = this.$store.state.usersAdminList.find(item => item.id === uid);
				return myu.name;
			}
			return "";
		},
		numdisplay: function (num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		hmsFromKevdate(str) {
			var arr = str.split("T");
			if(arr[1]) return arr[1];
			return str;
		},
		msFromsec(str) {
			var sec = parseInt(str);
			if(isNaN(sec)) return "";
			var mins = Math.floor(sec / 60);
			var rem = sec - (mins * 60);
			var remstr = rem.toString();
			if(rem < 10) remstr = "0" + rem.toString();
			return mins+":"+remstr;
		},
		fetchStationList() {
			var self = this;
			var targ = 'https://trackamedia.appspot.com/api/admin/getStationData';
			axios.get(targ).then(function(response) {
				var ret = response.data; //response.data is returned info
				ret = self.sortArrayByKey(ret, 'stationName');
				self.$store.commit('setAdminStationList', ret);
			}).catch(function(error) {
				console.log(error);
			});
		},
		fetchMarketsList() {
			var self = this;
			var targ = 'https://trackamedia.appspot.com/api/admin/getMarketsData';
			axios.get(targ, {auth: self.$store.state.basicAuthAdmin}).then(function(response) {
				var ret = response.data; //response.data is returned info
				ret = self.sortArrayByKey(ret, 'marketName');
				self.$store.commit('setAdminMarketList', ret);
			}).catch(function(error) {
				console.log(error);
			});
		},
		fetchStationMarketMappingList() {
			var self = this;
			var targ = 'https://trackamedia.appspot.com/api/admin/getStationMappingData';
			axios.get(targ, {auth: self.$store.state.basicAuthAdmin}).then(function(response) {
				var ret = response.data; //response.data is returned info
				self.$store.commit('setStationMarketMappingList', ret);
			}).catch(function(error) {
				console.log(error);
			});
		},
		fetchAdminData() {
			var self = this;
			var targ = '/post/get-admin-data.php';
			axios.get(targ).then(function(response) {
				var ret = response.data; //response.data is returned info
				self.$store.commit('setFromAdminReturns', ret);
			}).catch(function(error) {
				console.log(error);
			});
		},
		switchAdminTab(newtabname) {
			this.$store.commit('setActiveSectionAdmin', newtabname);
			this.fetchAdminData();
		},
		fetchPublicSelectData() {
			var self = this;
			var targ = '/post/get-public-select-data.php';
			axios.get(targ).then(function(response) {
				var ret = response.data; //response.data is returned info
				self.$store.commit('setFromPublicSelectReturns', ret);
			}).catch(function(error) {
				console.log(error);
			});
		},
		checkFetchUserAppData() { //triggered from front end for user context (not admin)
			if(this.$store.state.marketsAdminList.length === 0) {
				this.fetchMarketsList();
				this.fetchStationList();
				this.fetchStationMarketMappingList();
				this.fetchPublicSelectData();
			}
		},
		getEOHTime(timestr) {
			var datearr = timestr.split(" ");
			var timearr = datearr[1].split(":");
			return datearr[0] + "T" + timearr[0] + ":59:59Z";
		},
		showBugReporter(item) {
			this.$store.commit('setBugReportItem', item);
			this.$store.commit('showBugReporter', true);
		},
		showSongEditCard(songTrackaId) {
			this.$store.state.songEditTrackaId = songTrackaId;
			this.$store.state.songEditCardShown = true;
		},
		sendLoggingInfo(reportType, selections) {
			var form = {};
			form.reportName = reportType;
			form.market = selections.marketid;
			form.userid = this.$store.state.user.id;
			form.queryString = this.$store.state.lastQueryUrl;
			var targ = '/post/tracka-logger.php';
			axios.post(targ, form).then(function () {

			}).catch(function (error) {
				console.log(error);
			});
		},
		prepareExcelTableRows(flds, tabledata) {
			var mrows = [];
			//get header rows
			var topcells = [];
			for(var i=0; i<flds.length; i++) {
				topcells.push({val: flds[i].label});
			}
			mrows.push({isMainHeadingRow: true, isSubHeadingRow: false, cells: topcells});
			//get data rows
			for(var j=0; j<tabledata.length; j++) { //each of these is a row
				var mycells = [];
				for(var k=0; k<flds.length; k++) { //each of these is a cell in the row
					var fieldnm = flds[k].key;
					var myval = tabledata[j][fieldnm];
					if(fieldnm === 'songPlayStartTimestamp') {
						myval = this.hmsFromKevdate(myval);
					}
					else if(fieldnm === 'playedDurationMs') {
						myval = this.msFromsec(myval);
					}
					else if(fieldnm.indexOf('station') === 0 && this.$router.currentRoute.path === '/music-reports/market-side-by-side-log') {
						if(myval) {
							var tempval = myval.songArtists + " - " + myval.songTitle + " (" + myval.leastReleaseYear + ")";
							myval = tempval;
						}
						else myval = "";
					}
					mycells.push({val: myval});
				}
				mrows.push({isMainHeadingRow: false, isSubHeadingRow: false, cells: mycells});
			}
			return mrows;
		},
	},
});

//initialise app
new Vue({
	router,
	store: store,
	render: h => h(App),
}).$mount('#app')
