<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">
		<div id="app-container">
			<div id="logo-holder">
				<a href="https://app.tracka.app">
					<img src="@/assets/logo.png" alt="Logo" />
				</a>
			</div>

			<div v-cloak>

				<div id="form-wrapper">
					<div>
						<h2>Logging out...</h2>

					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'Logout',
	components: {},
	data() {
		return {

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Logging out";
	},
	created() {
		var form = {};
		form.dologout = 1;
		var self = this;
		axios.post(
			"post/logout.php",
			JSON.stringify({data: form})
		).then(function(response) {
			var ret = response.data; //response.data is returned info
			if(ret.error === 1) {
				self.$router.push("/error");
			}
			else if(ret.success === 1) {
				self.$store.commit('setUser', {id:0})
				self.$router.push("/login");
			}
		}).catch(function(error) {
			console.log(error);
		});

	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
#app-container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
.bgpage {
	/*background-image: url('~@/assets/form-bg.jpg');*/
}
#logo-holder img {
	height: 52px;
}
#form-wrapper {
	background: #FFF;
	border-radius: 30px;
	padding: 50px;
	margin-top: 40px;
	margin-bottom: 80px;
	box-shadow: 10px 0px 40px 6px rgba(0,0,0,.08);
}
</style>
