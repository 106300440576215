<template>
	<div class="">
		<div v-if="started" class="content-container report-section" :class="{sectionhide : drilldownOverlayShow === true}">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
					</h2>
					<p id="resultsSubheading">{{subheading}}</p>
				</div>

				<div style="width: 300px; display: flex; align-items: center">
					<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Type to Search"
							style="font-size: 13px;"
					></b-form-input>
					<div class="icon-excel-hold" v-if="returndata.length > 0" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
				</div>
			</div>

			<b-table :fields="fields" :items="returndata" :tbody-tr-class="rowClass" :filter="filter">
				<template #cell(songTitle)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder" v-if="data.item.isTOH !== true">
						<i class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(data.item);" title="Report an error with this item"></i>
					</div>
				</template>
			</b-table>

		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
	</div>
</template>

<script>
import axios from "axios";
import BugReporter from "@/components/BugReporter";
export default {
	name: "StationApraReport",
	components: {
		BugReporter,
	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: true,
				period: false,
				property: false,
				daypart: false,
				logdate: false,
				startdate: true,
				enddate: true,
			},
			reportType: 'StationApraReport', //for API URL
			fetching: false,
			started: false,
			reportTitle: "Station APRA Report",

			heading: 'Station APRA Report',
			subheading: 'Number of spins per month for each track in quarter',

			returndata: [],
			fields: [
				{key: 'artist', label: 'Artist', sortable: true},
				{key: 'title', label: 'Title', sortable: true},
				// {key: 'label', label: 'Label', sortable: true},
				{key: 'playedDurationMs', label: 'Duration', sortable: true},
				{key: 'month0', label: '', sortable: true},
				{key: 'month1', label: '', sortable: true},
				{key: 'month2', label: '', sortable: true},
			],
			filter: '',
			rowClass: 'trow',
		}
	},
	computed: {

	},
	methods: {
		getReport() {
			if(this.fetching === false) {
				this.drilldownOverlayShow = false; //force hide the drilldown reports
				if(this.selections.stationid === null || this.selections.marketid === null
					|| this.selections.logdateStart === null || this.selections.logdateEnd === null) {
					return false;
				}
				let self = this
				let params = "stationId=" + this.selections.stationid+"&logStartDate=" + this.selections.logdateStart+"&logEndDate=" + this.selections.logdateEnd;
				let targ = '/api-web/apra-report.php?' + params
				this.$store.commit('setLastQueryUrl', targ)
				this.fetching = true
				document.title = "Fetching data..."
				axios.get(targ).then(function(response) {
					let ret = response.data //response.data is returned info
					document.title = 'Tracka - '+self.reportTitle
					let res = []
					let months = []
					let mostmonths = 0
					for(let trackid in ret.tracks) {
						let myrow = ret.tracks[trackid]
						let myitem = {
							artist: myrow.songItem.artist,
							title: myrow.songItem.title,
							playedDurationMs: myrow.songItem.durationMinSec,
						};
						myitem.month0 = myitem.month1 = myitem.month2 = 0;
						for(let mindex=0; mindex<3; mindex++) {
							let monthrow = myrow.mthlyPlayHistory[mindex];
							if(monthrow) {
								let mycolid = 'month'+mindex;
								myitem[mycolid] = monthrow.totalPlays;
							}
						}
						//get the month names
						let nummonths = myrow.mthlyPlayHistory.length;
						if(myrow.mthlyPlayHistory[0] && myrow.mthlyPlayHistory[1] && myrow.mthlyPlayHistory[2]) {
							months[0] = myrow.mthlyPlayHistory[0].monthName;
							months[1] = myrow.mthlyPlayHistory[1].monthName;
							months[2] = myrow.mthlyPlayHistory[2].monthName;
							mostmonths = 3;
						}
						else if(nummonths === 2 && mostmonths < 2) {
							months[0] = myrow.mthlyPlayHistory[0].monthName;
							months[1] = myrow.mthlyPlayHistory[1].monthName;
							mostmonths = 2;
						}
						else if(nummonths === 1 && mostmonths < 1) {
							months[0] = myrow.mthlyPlayHistory[0].monthName;
							mostmonths = 1;
						}
						res.push(myitem);
					}
					for(let j=0; j<self.fields.length; j++) {
						let thisfield = self.fields[j];
						if(thisfield.key === 'month0') thisfield.label = months[0];
						if(thisfield.key === 'month1') thisfield.label = months[1];
						if(thisfield.key === 'month2') thisfield.label = months[2];
					}
					self.returndata = res
					self.fetching = false
					self.started = true //first one has come back
					self.heading = self.getStationNameFromId(self.selections.stationid) + " Log "
					self.sendLoggingInfo(self.reportType, self.selections);
				}).catch(function(error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata);
			form.layout = this.reportType;
			form.filename = this.getStationNameFromId(this.selections.stationid);
			var targ = '/post/get-excel.php';
			axios.post(targ, form).then(function (response) {
				window.location = response.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
		showBugReporter(item) {
			this.$store.commit('setBugReportItem', item);
			this.$store.commit('showBugReporter', true);
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects);
		document.title = 'Tracka - '+this.reportTitle;
	}
}
</script>

<style scoped>


</style>